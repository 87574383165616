import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FairPeriod } from '../model/fair-period';
import { FairPeriodBFactor } from '../model/fair-period-bfactor';
import { ToastrService } from './toastr.service';
import { Claim } from '../model/claim';

@Injectable({
    providedIn: 'root'
})

export class FairService {

    private endpoint = `${environment.api_endpoint}/fair`;

    constructor(private httpClient: HttpClient,
        private toastrService: ToastrService
    ) {
    }

    public getAllPeriodByClaimableStatus(): Observable<FairPeriod[]> {
        return this.httpClient.get<FairPeriod[]>(`${this.endpoint}/fairperiods/claimable`);
    }

    public getAllPeriodBFactorsByPeriodId(periodId: number): Observable<FairPeriodBFactor[]> {
        return this.httpClient.get<FairPeriodBFactor[]>(`${this.endpoint}/bfactorsforperiod/${periodId}`);
    }

    public getAllPeriodBFactorsByRightholder(): Observable<FairPeriodBFactor[]> {
        return this.httpClient.get<FairPeriodBFactor[]>(`${this.endpoint}/bfactorsforrightholder`);
    }


}
