import { HttpClient } from "@angular/common/http";
import { BaseService } from "../base/base-service";
import { FairRepertoireItem } from "../model/fair-repertoire-item";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Claim } from "../model/claim";

@Injectable({
    providedIn: 'root'
})
export class FairItemService extends BaseService<FairRepertoireItem> {

    constructor(httpClient: HttpClient) {
        super(httpClient, 'fairrepertoireitems');
    }

    create(): FairRepertoireItem {
        return {
            period: undefined,
            basedOnWorkId: undefined,
            title: undefined,
            info: undefined,
            limitMinutes: undefined,
            totalMinutes: undefined,
            periodBFactor: undefined,
            points: undefined,
            newPeriodBFactor: undefined,
            correctedPoints: undefined,
            isConflict: undefined,
            claimableWorkId: undefined
        };
    }

    getItemByWorkId(workId: number): Observable<FairRepertoireItem> {
        return this.httpClient.get<FairRepertoireItem>(`${this.endpoint}/byWorkId/${workId}`);
    }
}
