import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { Repartition } from '../../model/repartition';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-repartition-dialog',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, MatTableModule, DatePipe, MatPaginatorModule, MatRadioModule, FormsModule],
  templateUrl: './repartition-dialog.component.html',
  styleUrl: './repartition-dialog.component.scss'
})
export class RepartitionDialogComponent {
  repartitions: Repartition[];
  selectedRepartition: Repartition;

  displayedColumns = ['select', 'id', 'description', 'creationDate'];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Repartition[],
    private dialogRef: MatDialogRef<RepartitionDialogComponent>,
  ) {
    this.repartitions = data;
  }

  onSelect(repartition: Repartition) {
    this.selectedRepartition = repartition;
  }

  cancel() {  
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.selectedRepartition);
  }
}
