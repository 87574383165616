<mat-card class="card" appearance="raised">
    <div class="title-row">
        <mat-card-header>
            <mat-card-title>{{period.description}} ({{period.id}})</mat-card-title>
            <mat-card-subtitle>{{period.start | date: 'longDate'}} - {{period.end | date:
                'longDate'}}
            </mat-card-subtitle>
        </mat-card-header>
        <app-fair-period-status-badge [period]="period"></app-fair-period-status-badge>
    </div>
    @if (creating) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
    <mat-card-content>
        <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" [(selectedIndex)]="selTabIndex">
            <mat-tab label="Periode">
                <table>
                    <thead>
                        <tr>
                            <th class="left">Uitkering</th>
                            <th class="left">Reglement</th>
                            <th class="center">Punten &ge;</th>
                            <th class="center">Kdh</th>
                            <th class="right">Bedrag</th>
                            <th class="center">Rapport</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="left">{{period.firstPayment | date: 'shortDate'}}</td>
                            <td class="left">{{period.repertoireRule.description}}</td>
                            <td class="center">{{period.minPoints}}</td>
                            <td class="center">{{period.minKDH}}</td>
                            <td class="right">{{period.calcAmount | currency: 'EUR' : 'symbol': '1.0-2' }}</td>
                            <td class="center">
                                @if (canDownload) {
                                <button type="button" mat-icon-button (click)="downloadItemReport()"
                                    matTooltip="Titelrapport downloaden"
                                    [disabled]="creating"><mat-icon>download</mat-icon></button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>
            <mat-tab label="Claims" [disabled]="claimsDisabled()">
                <table>
                    <thead>
                        <tr>
                            <th class="left">Titels met claim</th>
                            <th class="center">Geclaimd%</th>
                            <th class="center">Dubbelclaims</th>
                            <th class="right">Geblokkeerd%</th>
                            <th class="center">Rapport</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="left">{{claimedTitles}} ({{claimedTitles/itemCount | percent: '1.1-1'}})</td>
                            <td class="center">{{claimedShare | percent: '1.1-1'}}</td>
                            <td class="center">{{doubleClaims}}</td>
                            <td class="right">{{blockedClaimShare | percent: '1.1-1'}}</td>
                            <td class="center">
                                @if (canDownload) {
                                <button type="button" mat-icon-button (click)="downloadClaimReport()"
                                    matTooltip="Claimrapport downloaden"
                                    [disabled]="creating"><mat-icon>download</mat-icon></button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>
            <mat-tab label="Repartitie" [disabled]="repartitionDisabled()">
                <table>
                    <thead>
                        <tr>
                            <th class="left">Nota's`</th>
                            <th class="center">Concept</th>
                            <th class="center">Goedgekeurd</th>
                            <th class="center">Afgehandeld</th>
                            <th class="right">Rapport</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="left">{{specCount}}</td>
                            <td class="center">{{conceptCount}}</td>
                            <td class="center">{{approvedCount}}</td>
                            <td class="center">{{bookkeepingCount}} ({{bookkeepingShare | percent:
                                '1.1-1'}})</td>
                            <td class="right">
                                @if (canDownload) {
                                <button type="button" mat-icon-button (click)="downloadRepartitionReport()"
                                    matTooltip="Repartitierapport downloaden"
                                    [disabled]="creating"><mat-icon>download</mat-icon></button>
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-tab>
        </mat-tab-group>


    </mat-card-content>

    <div id="action-rows">
        <div id="fair-row">

            @if (canApprove) {
            <button type="button" mat-stroked-button color="primary" (click)="approve()" [disabled]="creating"
                class="mat-elevation-z1"><mat-icon>thumb_up</mat-icon>Goedkeuren</button>

            <button [routerLink]="['/faircbo/period/items']"
                [queryParams]="{id: period.id, description: period.description}" mat-stroked-button
                [disabled]="creating" class="mat-elevation-z1"><mat-icon>edit</mat-icon>Aanpassen</button>

            <button type="button" mat-stroked-button color="warn" (click)="reject()" [disabled]="creating"
                class="mat-elevation-z1"><mat-icon>thumb_down</mat-icon>Afkeuren</button>
            }
        </div>
        <div id="sbf-row">
            @if (canReCreate) {
            <button type="button" mat-stroked-button (click)="recreate()" matTooltip="Opnieuw aanmaken"
                [disabled]="creating" class="mat-elevation-z1"><mat-icon>restart_alt</mat-icon>Opnieuw</button>
            }
            @if (canCreateWorks) {
            <button type="button" mat-stroked-button color="primary" matTooltip="Werken aanmaken"
                (click)="createWorks()" [disabled]="creating"
                class="mat-elevation-z1"><mat-icon>playlist_add</mat-icon>Werken</button>
            }
            @if (canSendEmail) {
            <button type="button" mat-stroked-button color="primary" matTooltip="Emails versturen"
                (click)="sendEmails()" [disabled]="creating" class="mat-elevation-z1"><mat-icon>email</mat-icon>Emails
                sturen</button>
            }
            @if (canDelete) {
            <button type="button" mat-stroked-button color="warn" (click)="delete()" [disabled]="creating"
                class="mat-elevation-z1"><mat-icon>delete_forever</mat-icon>Verwijderen</button>
            }

            @if (canCreateImportFile) {
            <button type="button" mat-stroked-button (click)="createImportFile()" [disabled]="creating"
                class="mat-elevation-z1"
                matTooltip="Importbestand downloaden"><mat-icon>cloud_download</mat-icon>Importbestand</button>

            <button mat-stroked-button (click)="linkRepartition()" [disabled]="creating"
                class="mat-elevation-z1"><mat-icon>link</mat-icon>Link repartitie</button>
            }

            @if (inRepartition) {
            <button type="button" mat-stroked-button class="mat-elevation-z1" matTooltip="Ga naar repartitie"
                (click)="navigateToRepartition()">Repartitie:
                {{period.repartition?.description}}<mat-icon>arrow_forward</mat-icon></button>
            }
        </div>
    </div>

</mat-card>