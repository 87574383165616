<mat-card>
  <mat-card-title>Selecteer een FAIR repartitie</mat-card-title>
  <mat-card-content>
    <table mat-table [dataSource]="repartitions" class="mat-elevation-z2">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>Selecteer</th>
        <td mat-cell *matCellDef="let row">
          <mat-radio-button [value]="row" (change)="onSelect(row)"></mat-radio-button>
        </td>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Omschrijving</th>
          <td mat-cell *matCellDef="let row">{{row.description}}</td>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>Aanmaakdatum</th>
          <td mat-cell *matCellDef="let row">{{row.creationDate | date:'dd-MM-yyyy'}}</td>
        </ng-container>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    @if(selectedRepartition) {
    <p>Geselecteerde FAIR repartitie: <b>{{selectedRepartition.description}}</b></p>
    } @else {
    <p><i>Nog geen FAIR repartitie geselecteerd</i> </p>
    }
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button (click)="cancel()">Annuleren</button>
    <button mat-raised-button color="primary" [disabled]="!selectedRepartition" (click)="save()">Opslaan</button>
  </mat-card-actions>

</mat-card>