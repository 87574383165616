import { Component, Input } from '@angular/core';
import { FairPeriodStatus } from '../../enums/fair-period-status.enum';
import { FairPeriod } from '../../model/fair-period';
import { DatePipe, NgClass } from '@angular/common';

@Component({
  selector: 'app-fair-period-status-badge',
  standalone: true,
  imports: [NgClass, DatePipe],
  templateUrl: './fair-period-status-badge.component.html',
  styleUrl: './fair-period-status-badge.component.scss'
})
export class FairPeriodStatusBadgeComponent {

  @Input() period: FairPeriod;

  get className(): string {
    switch (this.period.status) {
      case FairPeriodStatus.CREATED:
      case FairPeriodStatus.RECREATED:
        return "grey";
      case FairPeriodStatus.WORKS_CREATED:
      case FairPeriodStatus.APPROVED:
        return "blue";
      case FairPeriodStatus.CREATION_FAILED:
      case FairPeriodStatus.REJECTED:
      case FairPeriodStatus.NA:
      case FairPeriodStatus.DELETED:
      case FairPeriodStatus.WORKS_CREATION_FAILED:
        return "red";
      case FairPeriodStatus.CLAIMABLE:
      case FairPeriodStatus.IN_REPARTITION:
        return "green";
      case FairPeriodStatus.DONE:
      case FairPeriodStatus.DELETED:
        return "done";
      default:
        return ""; // Default case added to handle all possible enum values
    }
  }

  get statusLabel(): string {
    switch (this.period.status) {
      case FairPeriodStatus.NA:
        return 'n/a';
      case FairPeriodStatus.CREATED:
        return 'Aangemaakt';
      case FairPeriodStatus.CREATION_FAILED:
        return 'Aanmaakfout';
      case FairPeriodStatus.APPROVED:
        return 'Goedgekeurd';
      case FairPeriodStatus.REJECTED:
        return 'Afgekeurd';
      case FairPeriodStatus.RECREATED:
        return 'Opnieuw aangemaakt';
      case FairPeriodStatus.DELETED:
        return 'Verwijderd';
      case FairPeriodStatus.WORKS_CREATED:
        return 'Werken aangemaakt';
      case FairPeriodStatus.WORKS_CREATION_FAILED:
        return 'Aanmaakfout werken';
      case FairPeriodStatus.CLAIMABLE:
        return 'Claimfase';
      case FairPeriodStatus.IN_REPARTITION:
        return 'In repartitie';
      case FairPeriodStatus.DONE:
        return 'Afgerond';
      default:
        return ''; // Default case added to handle all possible enum values
    }
  }

}
