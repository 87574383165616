<mat-card-header>
    <mat-card-title>
        Creditnota voor: {{specification.rightHolder?.description}}
    </mat-card-title>
    <mat-card-subtitle>
        Periode vanaf {{repartition?.periodFrom | date: 'shortDate'}} tot {{repartition?.periodUntil | date: 'shortDate'}} (<span translate>LABEL.SPECIFICATIONSTATUS.{{specification.status | uppercase}}</span>)
    </mat-card-subtitle>
    <div>
        <button mat-icon-button (click)="goToSpecification()" matTooltip="Naar specificatie">
            <mat-icon>forward</mat-icon>
        </button>
        <button mat-icon-button (click)="close()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>
</mat-card-header>
<mat-card-content>
    <app-specification [specification]="specification" [repartition]="repartition" [amountMinusCosts]="amountMinusCosts"></app-specification>
    <mat-accordion multi="true" class="mat-accordion-div">

        <mat-expansion-panel expanded="true">
            <mat-expansion-panel-header>
                <mat-panel-title>{{'label.Financialdata' | uppercase | translate}}</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="mat-expansion-panel-content">
                    <app-financial-data [financialdata]="specification.rightHolder?.financialData"></app-financial-data>
                </div>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="showSpecificationLines" expanded="false">
            <mat-expansion-panel-header>
                <mat-panel-title>Specificatieregels</mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <div class="mat-expansion-panel-content">
                    <app-specification-lines [repartition]="repartition" [specification]="specification"></app-specification-lines>
                </div>
            </ng-template>
        </mat-expansion-panel>

    </mat-accordion>
</mat-card-content>
