import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { FairPeriodStatus } from 'src/app/shared/enums/fair-period-status.enum';
import { FairPeriod } from 'src/app/shared/model/fair-period';
import { FairPeriodStatusBadgeComponent } from 'src/app/shared/component/fair-period-status-badge/fair-period-status-badge.component';
import { MatDialog } from '@angular/material/dialog';
import { RepartitionDialogComponent } from 'src/app/shared/dialog/repartition-dialog/repartition-dialog.component';
import { Roles } from 'src/app/shared/enums/roles.enum';
import { SessionService } from 'src/app/shared/service/session.service';
import { FairPeriodService } from 'src/app/shared/service/fairperiod.service';
import { ToastrService } from 'src/app/shared/service/toastr.service';

@Component({
  selector: 'app-period',
  standalone: true,
  imports: [MatCardModule, MatTabsModule, MatChipsModule, DatePipe, CurrencyPipe, MatDividerModule, MatButtonModule,
    MatTooltipModule, MatIconModule, MatProgressBarModule, FairPeriodStatusBadgeComponent, PercentPipe, RouterLink],
  templateUrl: './period.component.html',
  styleUrl: './period.component.scss'
})
export class PeriodComponent implements OnInit {

  @Input() period: FairPeriod;
  creating: boolean = false;
  selTabIndex: number;

  itemCount: number = 0;
  claimedTitles: number = 0;
  doubleClaims: number = 0;
  claimedShare: number = 0;
  blockedClaimShare: number = 0;

  specCount: number = 0;
  conceptCount: number = 0;
  approvedCount: number = 0;
  bookkeepingCount: number = 0;
  bookkeepingShare: number = 0;

  roles: Roles[];
  isSBFAuthorized: boolean = false;

  displayedColumns = [
    'dates',
    'rule',
    'minpoints',
    'minkdh',
    'amount',
    'updated',
    'report'
  ];

  constructor(private router: Router,
    private sessionService: SessionService,
    private fairPeriodService: FairPeriodService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.isSBFAuthorized = this.sessionService.hasRole(Roles.SBF_DATAMANAGEMENT) || this.sessionService.hasRole(Roles.SBF_GENERALMANAGEMENT);
    this.selTabIndex = this.getInitialTabIndex();
    if (this.selTabIndex > 0) {
      // Get the total number of items
      this.fairPeriodService.getItemCount(this.period).subscribe(value => this.itemCount = value);
      // Get the total number of claimed titles
      this.fairPeriodService.getClaimedTitles(this.period).subscribe(value => this.claimedTitles = value);
      // Get the total number of claimed titles with a double claim
      this.fairPeriodService.getDoubleClaims(this.period).subscribe(value => this.doubleClaims = value);
      // Get the total share of claimed titles
      this.fairPeriodService.getClaimedShare(this.period).subscribe(value => this.claimedShare = value);
      // Get the total blocked share
      this.fairPeriodService.getBlockedClaimShare(this.period).subscribe(value => this.blockedClaimShare = value);

      if (this.selTabIndex > 1) {
        //Repartition stats
        this.fairPeriodService.getSpecCount(this.period).subscribe(value => this.specCount = value);
        this.fairPeriodService.getConceptCount(this.period).subscribe(value => this.conceptCount = value);
        this.fairPeriodService.getApprovedCount(this.period).subscribe(value => this.approvedCount = value);
        this.fairPeriodService.getBookkeepingCount(this.period).subscribe(value => this.bookkeepingCount = value);
        this.fairPeriodService.getBookkeepingShare(this.period).subscribe(value => this.bookkeepingShare = value);
      }
    }
  }

  getInitialTabIndex(): number {
    switch (this.period.status) {
      case FairPeriodStatus.NA:
      case FairPeriodStatus.CREATED:
      case FairPeriodStatus.CREATION_FAILED:
      case FairPeriodStatus.WORKS_CREATION_FAILED:
      case FairPeriodStatus.APPROVED:
      case FairPeriodStatus.REJECTED:
      case FairPeriodStatus.RECREATED:
      case FairPeriodStatus.DELETED:
      case FairPeriodStatus.DONE:
        return 0;
      case FairPeriodStatus.WORKS_CREATED:
      case FairPeriodStatus.CLAIMABLE:
        return 1;
      case FairPeriodStatus.IN_REPARTITION:
        return 2;
      default:
        return 0; // Default case added to handle all possible enum values
    }
  }

  get canDownload(): boolean {
    return ![FairPeriodStatus.CREATION_FAILED, FairPeriodStatus.DELETED].includes(this.period.status);
  }

  get canApprove(): boolean {
    if (this.isSBFAuthorized) {
      return this.selTabIndex === 0 && [FairPeriodStatus.CREATED, FairPeriodStatus.RECREATED].includes(this.period.status);
    }
    return false;
  }

  get canDelete(): boolean {
    return this.selTabIndex === 0 && [
      FairPeriodStatus.CREATED,
      FairPeriodStatus.APPROVED,
      FairPeriodStatus.RECREATED,
      FairPeriodStatus.CREATION_FAILED,
      FairPeriodStatus.REJECTED
    ].includes(this.period.status);
  }

  get canReCreate(): boolean {
    return this.selTabIndex === 0 && [
      FairPeriodStatus.CREATED,
      FairPeriodStatus.RECREATED,
      FairPeriodStatus.REJECTED
    ].includes(this.period.status);
  }

  get canCreateWorks(): boolean {
    if (this.isSBFAuthorized) {
      return this.selTabIndex === 0 && this.period.status === FairPeriodStatus.APPROVED;
    }
    return false;
  }

  get canSendEmail(): boolean {
    if (this.isSBFAuthorized) {
      return this.selTabIndex === 0 && this.period.status === FairPeriodStatus.WORKS_CREATED;
    }
    return false;
  }

  get canCreateImportFile(): boolean {
    if (this.isSBFAuthorized) {
      return this.selTabIndex === 1 && this.period.status === FairPeriodStatus.CLAIMABLE;
    }
    return false;
  }

  get inRepartition(): boolean {
    if (this.isSBFAuthorized) {
      return this.selTabIndex === 2 && this.period.repartition != null && this.period.status === FairPeriodStatus.IN_REPARTITION;
    }
    return false;
  }

  downloadItemReport() {
    this.fairPeriodService.getItemReport(this.period);
  }

  downloadClaimReport() {
    this.fairPeriodService.getClaimReport(this.period);
  }

  downloadRepartitionReport() {
    this.fairPeriodService.getRepartitionReport(this.period);
  }

  update(newStatus: FairPeriodStatus) {
    this.fairPeriodService.update({ ...this.period, status: newStatus }).subscribe(_ => {
      this.period.status = newStatus;
    })
  }

  approve() {
    this.update(FairPeriodStatus.APPROVED);
  }

  reject() {
    this.update(FairPeriodStatus.REJECTED);
  }

  delete() {
    alert("nog niet geïmplementeerd");
  }

  recreate() {
    this.creating = true;
    this.fairPeriodService.reCreateRepertoire(this.period).subscribe({
      next: (value) => {
        this.period = value;
        this.creating = false;
        this.toastr.success("Geslaagd", "Aanmaken repertoire");
      },
      error: () => {
        this.creating = false;
        this.toastr.error("Mislukt", "Aanmaken repertoire");
      }
    });
  }

  createWorks() {
    this.creating = true;
    this.fairPeriodService.createWorksFor(this.period).subscribe({
      next: (value) => {
        this.period = value;
        this.creating = false;
        this.toastr.success("Geslaagd", "Aanmaken werken");
      },
      error: () => {
        this.creating = false;
        this.toastr.error("Mislukt", "Aanmaken werken");
      }
    });
  }

  sendEmails() {
    this.creating = true;
    this.fairPeriodService.sendEmailsFor(this.period).subscribe({
      next: (value) => {
        this.period = value;
        this.creating = false;
        this.toastr.success("Geslaagd", "Emails versturen");
        this.selTabIndex = 1;
      },
      error: () => {
        this.creating = false;
        this.toastr.error("Mislukt", "Emails versturen");
      }
    });
  }

  createImportFile() {
    this.creating = true;
    this.fairPeriodService.createImportFileFor(this.period)
      .then(() => {
        console.log("Importbestand aangemaakt");
      })
      .catch(() => {
        console.log("Importbestand mislukt");
      })
      .finally(() => {
        this.creating = false;
      });
  }

  claimsDisabled(): boolean {
    return ![FairPeriodStatus.CLAIMABLE, FairPeriodStatus.IN_REPARTITION, FairPeriodStatus.DONE].includes(this.period.status);
  }

  repartitionDisabled(): boolean {
    return ![FairPeriodStatus.IN_REPARTITION, FairPeriodStatus.DONE].includes(this.period.status);
  }

  linkRepartition() {
    this.fairPeriodService.getUnlinkedRepartitions().subscribe({
      next: (value) => {
        const dialogRef = this.dialog.open(RepartitionDialogComponent, {
          data: value
        });
        dialogRef.afterClosed().subscribe({
          next: (result) => {
            if (result) {
              this.period.repartition = result;
              this.fairPeriodService.linkRepartition(this.period).subscribe({
                next: (updatedPeriod) => {
                  this.period = updatedPeriod;
                  this.toastr.success("Geslaagd", "Repartitie koppelen");
                },
                error: () => {
                  this.period.status = FairPeriodStatus.CLAIMABLE;
                  this.toastr.error("Mislukt", "Repartitie koppelen");
                }
              });
            }
          }
        });
      }
    });
  }

  navigateToRepartition() {
    this.router.navigate([`/repartitions/${this.period.repartition.id}`]);
  }
}

