import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FairPeriod } from "../model/fair-period";
import { Observable, timer } from 'rxjs';
import { FairRepertoireRule } from '../model/fair-repertoire-rule';
import { ToastrService } from './toastr.service';
import { ApiUtil } from '../util/api.util';
import { Repartition } from '../model/repartition';

@Injectable({
  providedIn: 'root'
})
export class FairPeriodService extends BaseService<FairPeriod> {

  constructor(httpClient: HttpClient, private toastrService: ToastrService) {
    super(httpClient, 'fairperiod');
  }

  create(): FairPeriod {
    return {
      calcAmount: 0,
      end: undefined,
      firstPayment: undefined,
      minKDH: 0,
      minPoints: 0,
      repertoireRule: undefined,
      start: undefined,
      status: undefined
    };
  }

  getRepertoireRules(): Observable<FairRepertoireRule[]> {
    return this.httpClient.get<FairRepertoireRule[]>(`${this.endpoint}/rules`);
  }

  createRepertoire(fairPeriod: FairPeriod): Observable<FairPeriod> {
    return this.httpClient.post<FairPeriod>(`${this.endpoint}/repertoire`, fairPeriod);
  }

  reCreateRepertoire(period: FairPeriod): Observable<FairPeriod> {
    return this.httpClient.put<FairPeriod>(`${this.endpoint}/recreaterepertoire`, period);
  }

  getItemCount(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/itemcount/${period.id}`);
  }

  getClaimedTitles(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/claimedtitles/${period.id}`);
  }

  getClaimedShare(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/claimedshare/${period.id}`);
  }

  getDoubleClaims(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/doubleclaims/${period.id}`);
  }

  getBlockedClaimShare(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/blockedclaimshare/${period.id}`);
  }

  public createWorksFor(period: FairPeriod): Observable<FairPeriod> {
    return this.httpClient.post<FairPeriod>(`${this.endpoint}/createworks`, period);
  }

  sendEmailsFor(period: FairPeriod): Observable<FairPeriod> {
    return this.httpClient.post<FairPeriod>(`${this.endpoint}/sendemails`, period);
  }

  public getItemReport(period: FairPeriod): Promise<Boolean> {
    return this.getReport(period, 'Titelrapport', 'itemreport', 'application/json');
  }

  public getClaimReport(period: FairPeriod): Promise<Boolean> {
    return this.getReport(period, 'Claimrapport', 'claimreport', 'application/json');
  }

  public getRepartitionReport(period: FairPeriod): Promise<Boolean> {
    return this.getReport(period, 'Repartitierapport', 'repartitionreport', 'application/json');
  }

  createImportFileFor(period: FairPeriod): Promise<Boolean> {
    return this.getReport(period, 'Importbestand', 'createimportfile', 'text/csv');
  }

  public getReport(period: FairPeriod, title: string, url: string, contentType: string): Promise<Boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.toastrService.info(`Bezig met ophalen ${title}`, '', {
        showDuration: '0',
        hideDuration: '0',
        timeOut: '0',
        extendedTimeOut: '0'
      });
      this.httpClient.get(`${this.endpoint}/${url}/${period.id}`, {
        responseType: 'blob',
        observe: 'response',
        headers: new HttpHeaders({
          'Content-Type': contentType
        })
      }).subscribe({
        next: (response) => {
          ApiUtil.saveToFileSystem(response);
          this.toastrService.clear();
          resolve(true);
          timer(250).subscribe(() => {
            this.toastrService.success('Geslaagd', `Ophalen ${title}`);
          });
        },
        error: (error) => {
          this.toastrService.clear();
          reject(error.statusText);
          timer(300).subscribe(() => {
            this.toastrService.error('Mislukt', `Ophalen ${title}`);
          });
        }
      });
    });
  }

  getUnlinkedRepartitions(): Observable<Repartition[]> {
    return this.httpClient.get<Repartition[]>(`${this.endpoint}/unlinkedrepartitions`);
  }

  linkRepartition(period: FairPeriod,): Observable<FairPeriod> {
    return this.httpClient.put<FairPeriod>(`${this.endpoint}/linkrepartition`, period);
  }

  getSpecCount(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/speccount/${period.id}`);
  }

  getConceptCount(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/conceptcount/${period.id}`);
  }

  getApprovedCount(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/approvedcount/${period.id}`);
  }

  getBookkeepingCount(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/bookkeepingcount/${period.id}`);
  }

  getBookkeepingShare(period: FairPeriod): Observable<number> {
    return this.httpClient.get<number>(`${this.endpoint}/bookkeepingshare/${period.id}`);
  }







}
